import {
  DeliveryNoteHarvest,
  DeliveryNoteProductionData,
  DeliveryNoteStateModel,
} from '@store/mobile/delivery-note/delivery-note.model';
import { DefaultPackRate } from '@core/models/defaultPackRate.model';

export class PatchDeliveryNote {
  public static readonly type = '[Delivery Note] Delivery note patched';

  constructor(public deliveryNoteInformation: Partial<DeliveryNoteStateModel>) {}
}

export class PatchDeliveryNoteProduction {
  public static readonly type = '[Delivery Note] Delivery note production data patched';

  constructor(public deliveryNoteProductionData: Partial<DeliveryNoteProductionData>) {}
}

export class SetDefaultPackRateDeliveryNote {
  public static readonly type = '[Delivery Note] Set default pack rate';

  constructor(public defaultPackRate: DefaultPackRate) {}
}

export class ResetDeliveryNote {
  public static readonly type = '[Delivery Note] Reset Delivery note';
}

export class AddDeliveryNoteHarvest {
  public static readonly type = '[Delivery Note] Harvest added';

  constructor(public harvest: DeliveryNoteHarvest) {}
}
