import { Action, State, StateContext } from '@ngxs/store';
import { DeliveryNoteStateModel } from '@store/mobile/delivery-note/delivery-note.model';
import {
  AddDeliveryNoteHarvest,
  PatchDeliveryNote,
  PatchDeliveryNoteProduction,
  ResetDeliveryNote,
  SetDefaultPackRateDeliveryNote,
} from '@store/mobile/delivery-note/delivery-note.actions';
import { Injectable } from '@angular/core';

@State<Partial<DeliveryNoteStateModel>>({
  name: 'deliveryNote',
  defaults: {
    deliveryNoteHarvests: [],
  },
})
@Injectable()
export class DeliveryNoteState {
  @Action(PatchDeliveryNote)
  public patch(ctx: StateContext<DeliveryNoteStateModel>, { deliveryNoteInformation }: PatchDeliveryNote): void {
    const state = ctx.getState();
    const item = { ...state.deliveryNoteInformation, ...deliveryNoteInformation };

    ctx.patchState({
      ...state,
      deliveryNoteInformation: item,
    });
  }

  @Action(PatchDeliveryNoteProduction)
  public patchProductionData(
    ctx: StateContext<DeliveryNoteStateModel>,
    { deliveryNoteProductionData }: PatchDeliveryNoteProduction,
  ): void {
    const state = ctx.getState();
    const item = { ...state.deliveryNoteProductionData, ...deliveryNoteProductionData };

    ctx.patchState({
      ...state,
      deliveryNoteProductionData: item,
    });
  }

  @Action(SetDefaultPackRateDeliveryNote)
  public setPackRate(
    ctx: StateContext<DeliveryNoteStateModel>,
    { defaultPackRate }: SetDefaultPackRateDeliveryNote,
  ): void {
    const state = ctx.getState();
    const item = { ...state.defaultPackRate, ...defaultPackRate };

    ctx.patchState({
      ...state,
      defaultPackRate: item,
    });
  }

  @Action(ResetDeliveryNote)
  public reset(ctx: StateContext<DeliveryNoteStateModel>): void {
    ctx.setState({
      deliveryNoteHarvests: [],
    });
  }

  @Action(AddDeliveryNoteHarvest)
  public addHarvest(ctx: StateContext<DeliveryNoteStateModel>, { harvest }: AddDeliveryNoteHarvest): void {
    const state = ctx.getState();
    const deliveryNoteHarvests = [...state.deliveryNoteHarvests, harvest];

    ctx.patchState({
      ...state,
      deliveryNoteHarvests,
    });
  }
}
